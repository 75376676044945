@import '~antd/es/style/themes/default.less';
@import '~antd/dist/antd.less';

@color-primary: #0C356A ;
@color-green-primary: #1E9553;
@color-green-2: #1E9553;
@border-radius-primary: 6px;
@text-grey: rgba(62, 75, 92, 0.8);
@text-grey-2: #adadad;
@color-red-primary : rgba(255, 54, 54, 1);
@color-orange-primary: rgb(255, 191, 54);
@color-pink-primary : #FF3636;

.bg-red-primary{
    background: @color-red-primary !important;
}
.bg-orange-primary {
    background: @color-orange-primary !important;
}

.bg-green-primary {
    background: @color-green-primary !important;
}


.clickable {
    cursor: pointer;
}

.text-grey-1 {
    color: @text-grey !important;
}
.text-grey-2{
    color: @text-grey-2 !important;
}

.text-primary-new {
    color: @color-primary !important;
}

.stroke-primary-new {
    stroke: @color-primary !important;
}

.text-pink-new{
    color: @color-pink-primary !important;
}

.section-pagination-with-text .ant-pagination-item {
    border-radius: 8px !important;
}
.border-grey {
    border: 1px solid @text-grey-2;
}
.border-radius-primary {
   border-radius: @border-radius-primary;
}

.section-pagination-with-text .ant-pagination-item-link {
    border-radius: 8px !important;
}

.main-tooltip {
    background: rgba(255, 255, 255, 225);
    padding: 8px 8px;
    border-radius: 8px;
    color: gray;
    font-size: 20px;
    border-style: none;
    border-width: 0px;
}

.main-tooltip .container-tooltip {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.main-tooltip .item-tooltip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
}

.text-right {
    text-align: right;
}

.text-bold {
    font-weight: bold;
}

.ant-switch-checked.switch-primary {
    background-color: @color-primary !important;
}

.ant-input.input-primary {
    border-radius: @border-radius-primary !important;
}

.ant-input.input-primary:hover{
    border-color: @color-primary !important;
}
.ant-input.input-primary:active {
    border-color: @color-primary !important;
}
.ant-input.input-primary:focus {
    border-color: @color-primary !important;
}

.ant-input.input-primary {
    border-radius: @border-radius-primary !important;
}

.ant-input.input-primary:hover{
    border-color: @color-primary !important;
}
.ant-input.input-primary:active {
    border-color: @color-primary !important;
}
.ant-input.input-primary:focus {
    border-color: @color-primary !important;
}

.ant-input-password.input-primary {
    border-radius: @border-radius-primary !important;
}

.ant-input-password.input-primary:hover {
    border-color: @color-primary !important;
}

.ant-input-password.input-primary:active {
    border-color: @color-primary !important;
}

.ant-btn-primary.btn-primary{
    border-color: @color-primary !important;
    background: @color-primary !important;
    border-radius: @border-radius-primary;
}

.ant-btn-primary.btn-primary:hover {
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10) !important;
    transform: translate(0px, 4px) !important;
}

.ant-menu.sider-v3-new {
    padding-left: 4px !important;
}

.ant-menu.sider-v3-new .ant-menu-item{
    color: @text-grey !important;
}

.ant-menu.sider-v3-new .ant-menu-submenu-selected .ant-menu-submenu-title{
    color: @color-primary !important;
    font-weight: bold;
}

.ant-input-affix-wrapper.input-primary:hover {
    border-color: @color-primary !important;
}
.ant-input-affix-wrapper.input-primary:focus, .ant-input-affix-wrapper-focused.input-primary {
    border-color: @color-primary !important;
}



.ant-menu.sider-v3-new .ant-menu-submenu-active .ant-menu-submenu-title:hover {
    color: @color-primary !important;
    font-weight: bold;
}

.ant-menu.sider-v3-new .ant-menu-item .ant-menu-item-active .ant-menu-title-content a:hover {
    color: @color-primary !important;
    font-weight: bold;
}

.ant-menu.sider-v3-new .ant-menu-submenu-selected:hover {
    color: @color-primary !important;

}


.ant-menu.sider-v3-new .ant-menu-submenu-arrow {
    color: @color-primary !important;

}

.ant-menu.sider-v3-new .ant-menu-item-active:hover {
    color: @color-primary !important;
    font-weight: bold;
}

.ant-menu.sider-v3-new .ant-menu-item-icon{
    margin-left: 16px !important;
}

.checkbox-primary .ant-checkbox-checked .ant-checkbox-inner{
    border-color: @color-primary !important;
        background: @color-primary !important;
        border-radius: @border-radius-primary;
}

.checkbox-primary .ant-checkbox-checked .ant-checkbox-inner:hover {
    border-color: @color-primary !important;
    
}

.checkbox-primary .ant-checkbox-input {
        border-color: @color-primary !important;

    
}

.checkbox-primary .ant-checkbox-input .ant-.ant-checkbox-inner:hover{
        border-color: @color-primary !important;

}

.btn-default-new {
    border-radius: @border-radius-primary !important;
    border: 1px solid @color-primary !important;
}

.btn-default-new:hover {
    border: 1px solid @color-primary !important;
    color: @color-primary !important;
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10) !important;
        transform: translate(0px, 4px) !important;
}

.btn-default-new:focus {
    border: 1px solid @color-primary !important;
    color: @color-primary !important;
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10) !important;
    transform: translate(0px, 4px) !important;
}

.btn-green-primary-new {
    border-radius: @border-radius-primary !important;
    border: 1px solid @color-green-primary !important;
    background-color: @color-green-primary !important;
    color: white !important;
}

.btn-green-primary-new:hover {
    border: 1px solid @color-green-primary !important;
    background-color: @color-green-primary !important;
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10) !important;
    transform: translate(0px, 4px) !important;
}

.btn-green-primary-new:focus {
    border: 1px solid @color-green-primary !important;
    background-color: @color-green-primary !important;
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10) !important;
    transform: translate(0px, 4px) !important;
}

.btn-green-primary-2-new {
    border-radius: @border-radius-primary !important;
    border: 1px solid @color-green-primary !important;
    background-color: @color-green-primary !important;
    color: white !important;
}

.btn-green-primary-2-new:hover {
    border: 1px solid @color-green-primary !important;
    background-color: @color-green-primary !important;
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10) !important;
    transform: translate(0px, 4px) !important;
}

.item-pagination-primary .ant-pagination-item-active{
    border-color: @color-primary;
    background-color: @color-primary;
    color: white !important;
}

.item-pagination-primary .ant-pagination-item:hover {
    border: 1px solid @color-primary !important;
        color: @color-primary !important;
}  

.item-pagination-primary .ant-pagination-item a:hover {
    color: @color-primary !important;
}

.item-pagination-primary .ant-pagination-item-active a {
    color: white !important;
}

.item-pagination-primary .ant-pagination-item-active a:hover {
    color: white !important;
}

.item-pagination-primary .ant-pagination-item-ellipsis :hover{
    color: @color-primary !important;
}
.item-pagination-primary .ant-pagination-prev a, .item-pagination-primary .ant-pagination-next a{
    color: @color-primary !important;
}

.modal-search-new .ant-modal-content{
    min-height: 100vh;
}
.modal-search-new  {
    width: 360px !important;
}
.main-table-page{
    border-radius: 16px !important;
}

.main-table-page .ant-table-thead .ant-table-cell {
    text-transform: uppercase;
    color:@color-primary;
    padding: 20px 16px !important;
    background: rgba(12, 53, 106, 0.1);
    border-left: none !important;
    border-right: none !important;
}

.main-table-page.head-white .ant-table-thead .ant-table-cell {
    text-transform: uppercase;
    color: @color-primary;
    padding: 20px 16px !important;
    background: white;
    border-left: none !important;
    border-right: none !important;
}

.main-table-page .ant-table-tbody .ant-table-cell {
    color: @text-grey;
    padding: 20px 16px !important;
    border-left: none !important;
    border-right: none !important;
}




.radio-btn-new .ant-radio-button-wrapper:hover{
    color: @color-primary !important;
}

.radio-btn-new .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
    color: white !important;
}
.radio-btn-new .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: @color-primary !important;
}
.ant-picker.date-picker-new {
    border-radius: @border-radius-primary;
}
.ant-picker.date-picker-new:hover{
    border-color: @color-primary;
}

.ant-picker.date-picker-new.ant-picker-focused{
    border-color: @color-primary;
}

.date-picker-popup-new .ant-picker-cell-selected .ant-picker-cell-inner{
    background: @color-primary !important;
    border-radius: @border-radius-primary;
}

.date-picker-popup-new .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before  {
    border-color: @color-primary !important;
    border-radius: @border-radius-primary;
}

.ant-select.input-primary .ant-select-selector {
    border-radius: @border-radius-primary !important;
}

.ant-select.input-primary .ant-select-selector:hover {
    border-color: @color-primary !important;
}

.ant-select.input-primary .ant-select-selector:active {
    border-color: @color-primary !important;
}

.ant-select.input-primary.ant-select-focused {
    border-color: @color-primary !important;
}

.ant-select.input-primary.ant-select-focused .ant-select-selector {
    border-color: @color-primary !important;
}

.ant-input-affix-wrapper.input-primary{
    border-radius: @border-radius-primary;
}

.upload-select-new .ant-upload{
    width: 100%;
}

.with-btn .ant-tabs-ink-bar {
    background-color: transparent !important;
}

.with-btn .ant-tabs-tab+.ant-tabs-tab{
    margin: 0px 0px 0px 8px;
}

.tab-default-new .ant-tabs-ink-bar {
    background-color: @color-primary !important;
   
}

.tab-default-new .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: @color-primary !important;
}

.tab-default-new .ant-tabs-tab .ant-tabs-tab-btn:hover {
    color: @color-primary !important;
}

.btn-link {
    background:rgba(12, 53, 106, 0.1) !important;
    border: none !important ;
    color: @color-primary !important;
}

.tag-primary-new {
    color: white;
    border-radius: 4px;
    text-transform: uppercase;
    background-color: @color-primary;
    padding: 1px 2px;
    height: fit-content;
    width: fit-content;
}
@primary-color: #1890ff;