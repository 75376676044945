/* .main-tooltip{
    background: rgba(0, 0, 0, 0.9);
    padding: 8px  8px;
    border-radius: 8px;
    color: whitesmoke;
}

.main-tooltip .container-tooltip{
   display: flex;
   flex-direction: column;
   gap: 4px;
}
.main-tooltip .item-tooltip{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
 } */

 .icon-svg-new.fil1-light {
    fill: #FF9933;
    fill-rule: nonzero
 }
  .icon-svg-new.fil0-light {
    fill: white;
    fill-rule: nonzero
  }

   .icon-svg-new.fil1-dark {
       fill: #26205E;
       fill-rule: nonzero
   }

   .icon-svg-new.fil0-dark {
       fill: #FF9933;
       fill-rule: nonzero
   }

   img {
    position: relative;
  }
  
  img:hover::after {
    content: attr(title); /* Display the value of the title attribute */
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    border-radius: 5px;
    bottom: 100%; /* Position the tooltip above the image */
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 12px;
    cursor: pointer;
  }